import React from 'react';

const SvgIconCheck = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 14A7 7 0 107 0a7 7 0 000 14zm3.786-8.049A.9.9 0 009.633 4.57l-3.59 2.995-1.685-1.372a.9.9 0 00-1.136 1.396l2.26 1.84a.9.9 0 001.144-.007l4.16-3.47z"
    />
  </svg>
);

export default SvgIconCheck;
