import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import Head from 'next/head';
import React, { useEffect, useState } from 'react';
import {
  SP_GetPortalConfig,
  SP_GetPortalConfig_getStudentPortalConfig_propertyWithEnabledAcademicYear,
} from '~graphql/__generated__/SP_GetPortalConfig';
import { GET_PORTAL_CONFIG } from '~graphql/user';
import { setCookie } from '~utils/cookie-operation';

export enum OrgOptions {
  YUGO = 'YUGO',
  URE = 'URE',
  COLIVING = 'COLIVING',
  BEYOO = 'BEYOO',
}

const URE_PORTAL_HOSTS = [
  'resident-portal.youniq-living.com',
  'another-portal.project-g66.com',
  'another-portal-uat1.project-g66.com',
  'another-portal-uat2.project-g66.com',
  'another-portal-uat3.project-g66.com',
  'another-portal-uat4.project-g66.com',
  'another-portal-uat5.project-g66.com',
];

const BEYOO_PORTAL_HOSTS = [
  'resident-portal.beyoo-living.at',
  'coliving-portal.project-g66.com',
  'coliving-portal-uat1.project-g66.com',
  'coliving-portal-uat2.project-g66.com',
  'coliving-portal-uat3.project-g66.com',
  'coliving-portal-uat4.project-g66.com',
  'coliving-portal-uat5.project-g66.com',
  'localhost',
];

export interface IThemeContextProps {
  ORG: OrgOptions | '';
  setOrg: (value: OrgOptions) => void;
  topLandlordId: string;
  propertyWithEnabledAcademicYear: SP_GetPortalConfig_getStudentPortalConfig_propertyWithEnabledAcademicYear[];
}

export const ThemeProviderContenxt = React.createContext<IThemeContextProps>({
  ORG: '',
  setOrg: () => {},
  topLandlordId: '',
  propertyWithEnabledAcademicYear: [],
});

const ThemeProviderProvider: React.FC = ({ children }) => {
  const [org, setOrg] = useState(OrgOptions.YUGO);

  const [portalTitle, setPortalTitle] = useState('');
  const [favicon, setFavicon] = useState('');

  const [topLandlordId, setTopLandlordId] = useState('');

  const [propertyWithEnabledAcademicYear, setPropertyWithEnabledAcademicYear] = useState<
    SP_GetPortalConfig_getStudentPortalConfig_propertyWithEnabledAcademicYear[]
  >([]);

  const { loading } = useQuery<SP_GetPortalConfig, null>(GET_PORTAL_CONFIG, {
    ssr: false,
    onCompleted: data => {
      setTopLandlordId(data?.getStudentPortalConfig?.topLandlordId || '');
      setCookie('FILE_SVC_URL', `${data?.getStudentPortalConfig?.fileServiceHost}/sp/`, 365);
      setPropertyWithEnabledAcademicYear(
        data?.getStudentPortalConfig?.propertyWithEnabledAcademicYear
      );
    },
  });

  useEffect(() => {
    const hostname = window.location.hostname;
    if (!hostname) return;
    if (URE_PORTAL_HOSTS.includes(hostname)) {
      document.body.setAttribute('class', 'ure');
      setPortalTitle('Resident Portal');
      setFavicon('/static/imgs/favicon-youniq.png');
      setOrg(OrgOptions.URE);
    } else if (BEYOO_PORTAL_HOSTS.includes(hostname)) {
      document.body.setAttribute('class', 'beyoo');
      setOrg(OrgOptions.BEYOO);
      setPortalTitle('Resident Portal');
      setFavicon('/static/imgs/favicon-beyoo.png');
    } else {
      setFavicon('/static/imgs/favicon.png');
      setPortalTitle('Student Portal');
    }
  }, []);

  return (
    <ThemeProviderContenxt.Provider
      value={{
        ORG: org,
        setOrg: setOrg,
        topLandlordId,
        propertyWithEnabledAcademicYear,
      }}
    >
      <Head>
        <title>{portalTitle}</title>
        <link rel="icon" type="image/png" href={favicon} />
      </Head>
      {!loading && children}
    </ThemeProviderContenxt.Provider>
  );
};

export default ThemeProviderProvider;

export const useThemeContext = () => React.useContext(ThemeProviderContenxt);
